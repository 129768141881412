import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="min-h-screen bg-red-100 flex flex-col items-center justify-center">
      <div className="bg-white p-10 rounded-lg shadow-xl text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-6">Ödəniş xətası</h1>
        <p className="text-lg text-gray-700 mb-4">
          Ödənişinizi edərkən xəta ilə qarşılaşdıq. Yenidən cəhd edin və ya
          problem davam edərsə, dəstək xidməti ilə əlaqə saxlayın.
        </p>
        <div className="space-x-4">
          <Link
            to="/"
            className="text-white bg-red-500 hover:bg-red-600 font-bold py-2 px-4 rounded"
          >
            Ana səhifəyə qayıdın.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

import React from "react";
import Login from "../components/Login/Login.jsx";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Login />
    </div>
  );
};

export default LoginPage;

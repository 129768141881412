import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  DashboardPage,
  PlanPage,
  ResultPage,
  SuccessPage,
  ErrorPage,
  ResetPassword,
} from "./Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store.js";
import { loadUser } from "./redux/actions/user.js";
import { useSelector } from "react-redux";

const App = () => {
  useEffect(() => {
    Store.dispatch(loadUser());
  }, []);

  const loading = useSelector((state) => state.user.loading);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route path="/plan" element={<PlanPage />} />
        <Route path="/payment/success" element={<SuccessPage />} />
        <Route path="/payment/error" element={<ErrorPage />} />
        <Route path="/payment/result" element={<ResultPage />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />

        <Route path="/" element={<MainRoute />} />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

const MainRoute = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  return isAuthenticated ? <DashboardPage /> : <Navigate to="/login" />;
};

export default App;

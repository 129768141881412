import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/App.css";

const Plan = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tab, setTab] = useState("plans");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const currentUserPlan = useSelector((state) => state.user?.user || {});
  const navigate = useNavigate();

  const plans = [
    { name: "free", displayName: "Pulsuz", features: ["5 sual"], price: 0 },
    {
      name: "standard",
      displayName: "Standart",
      features: ["4 AZN, 150 sual"],
      price: 4.0,
    },
    {
      name: "premium",
      displayName: "Premium",
      features: ["9 AZN, 500 sual"],
      price: 9.0,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/login", { replace: true }); // Ensure navigation has occurred
    window.location.reload(); // This will refresh the page
    toast.info("Çıxış etdiniz.");
  };

  const handlePlanSelect = async (plan) => {
    if (plan.price === 0) return;
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append('user_email', currentUserPlan.email);
    formData.append('name', plan.name);
    formData.append('amount', plan.price);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (!result.data || !result.signature) {
        throw new Error("Data or signature missing from the API response");
      }

      // Create a form to submit data and signature to the third-party service
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://epoint.az/api/1/checkout";
      form.acceptCharset = "utf-8";

      // Append data input to form
      const dataInput = document.createElement("input");
      dataInput.type = "hidden";
      dataInput.name = "data";
      dataInput.value = result.data;
      form.appendChild(dataInput);

      // Append signature input to form
      const signatureInput = document.createElement("input");
      signatureInput.type = "hidden";
      signatureInput.name = "signature";
      signatureInput.value = result.signature;
      form.appendChild(signatureInput);

      // Append the form to the body and submit
      document.body.appendChild(form);
      form.submit();

    } catch (error) {
      console.error("Payment initiation failed:", error);
      setError(
        error.message || "Failed to initiate payment. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (selectedTab) => {
    setTab(selectedTab);
  };

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Şifrələr eyni olmalıdır.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const token = localStorage.getItem("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/update-password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ password: newPassword }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      toast.success("Şifrə uğurla yeniləndi!");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error("Şifrəni yenilərkən xəta çıxdı:", error);
      setError(
        error.message || "Şifrəni yenilərkən xəta çıxdı. Zəhmət olmasa yenidən yoxlayın."
      );
      toast.error(
        error.message || "Şifrəni yenilərkən xəta çıxdı. Zəhmət olmasa yenidən yoxlayın."
      );
    } finally {
      setLoading(false);
    }
  };

  const renderTabContent = () => {
    switch (tab) {
      case "updatePassword":
        return (
          <div className="min-h-[40vh] p-6 rounded-lg shadow-lg bg-white">
            <form
              onSubmit={handlePasswordUpdate}
              className="flex flex-col gap-y-4"
            >
              <h2 className="font-medium mb-6 text-lg">Şifrənizi yeniləyin</h2>
              <input
                type="password"
                placeholder="Yeni şifrə"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                minLength={6}
              />
              <input
                type="password"
                placeholder="Yeni şifrəni təsdiqlə"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                minLength={6}
              />
              <button
                type="submit"
                style={{ backgroundColor: "blue", color: "white" }}
              >
                Şifrəni yenilə
              </button>
            </form>
            {error && <p className="error">{error}</p>}
          </div>
        );
      case "plans":
      default:
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 min-h-[40vh]">
              {plans.map((plan) => (
                <div
                  key={plan.name}
                  className="bg-white p-6 rounded-lg shadow-lg relative"
                >
                  <h2 className="text-2xl font-bold text-center mb-4">
                    {plan.displayName}
                  </h2>
                  <ul className="list-disc space-y-2 mb-12 md:mb-0 px-6">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="text-gray-700">
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => handlePlanSelect(plan)}
                    className={`py-2 rounded text-white font-bold absolute bottom-4 w-[86%] sm:w-[92%] md:w-[78%] ${plan.name === currentUserPlan.plan || plan.name === "free"
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-600"
                      }`}
                    disabled={
                      plan.name === currentUserPlan.plan || plan.name === "free"
                    }
                  >
                    {plan.name === currentUserPlan.plan
                      ? "Mövcud Plan"
                      : `${plan.displayName} seçin`}
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={handleLogout}
              className="mt-6 py-2 w-full bg-red-600 hover:bg-red-700 text-white font-bold rounded"
            >
              Çıxış et
            </button>
          </>
        );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error && tab !== "updatePassword") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full md:w-[692px] min-h-[50vh]">
      <div className="tabs flex justify-center mb-6 gap-x-2">
        <button
          onClick={() => handleTabChange("plans")}
          className={`tab flex-1 ${tab !== "plans" && "bg-gray-500 hover:bg-gray-500"
            }`}
        >
          Planlar
        </button>
        <button
          onClick={() => handleTabChange("updatePassword")}
          className={`tab flex-1 ${tab !== "updatePassword" && "bg-gray-500 hover:bg-gray-500"
            }`}
        >
          Şifrəni yenilə
        </button>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default Plan;

import React from 'react'
import Signup from '../components/Signup/Signup';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const SignupPage = () => {
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div><Signup /></div>
    )
}

export default SignupPage
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Store from './redux/store';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Use the new createRoot API to mount your app
const container = document.getElementById("root");
const root = createRoot(container); // Create a root.

root.render(
  <Provider store={Store}>
    <App />
  </Provider>
);

reportWebVitals();

import React from "react";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div className="min-h-screen bg-green-100 flex flex-col items-center justify-center">
      <div className="bg-white p-10 rounded-lg shadow-xl text-center">
        <h1 className="text-3xl font-bold text-green-600 mb-6">
          Ödəniş uğurla həyata keçdi!
        </h1>
        <p className="text-lg text-gray-700 mb-4">
          Ödəniş üçün təşəkkür edirik. Sizin əməliyyatınz uğurla həyata keçdi!
        </p>
        <Link
          to="/"
          className="text-white bg-green-500 hover:bg-green-600 font-bold py-2 px-4 rounded"
        >
          Ana səhifəyə qayıdın.
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;

import axios from "axios";
import * as actionTypes from './actionTypes';

export const setUserToken = (token) => ({
  type: actionTypes.SET_USER_TOKEN,
  payload: token,
});

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOAD_USER_REQUEST });

    const token = localStorage.getItem("access_token");

    if (!token) {
      throw new Error("Access token not found");
    }

    const response = await axios.get(`${process.env.REACT_APP_AUTH_URL}/getuser`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const userData = response.data.user;
    dispatch({ type: actionTypes.LOAD_USER_SUCCESS, payload: userData });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || "Failed to load user";
    dispatch({ type: actionTypes.LOAD_USER_FAIL, payload: errorMessage });
  }
};

import React, { useRef, useState } from "react";
import logo from "../../assets/img/logo.svg";
import { IoMdClose } from "react-icons/io";
import { useClickOutside } from "../../hooks/useClickOutside";
import axios from "axios";

export default function Sidebar({ setIsSidebarOpen }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const ref = useRef(null);
  const [feedback, setFeedback] = useState("");
  useClickOutside(ref, () => setIsSidebarOpen(false));

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitFeedback = async () => {
    if (!feedback.trim()) {
      alert("Zəhmət olmasa təklif və iradlarınızı daxil edin.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/feedback`,
        {
          content: feedback.trim(),
        }
      );

      if (response.status === 201) {
        alert("Rəyiniz uğurla qəbul edildi!");
        setModalOpen(false);
        setFeedback("");
      }
    } catch (error) {
      alert("Rəyiniz göndərilərkən xəta ortaya çıxdı. Zəhmət olmasa biraz sonra yenidən yoxlayın.");
    }
  };

  return (
    <div
      ref={ref}
      className="w-96 h-full text-white flex flex-col overflow-y-auto"
      style={{
        backgroundImage: "linear-gradient(191deg, #001252, #00092b)",
      }}
    >
      <div className="p-4 self-start flex justify-between w-full">
        <img src={logo} alt="Company Logo" className="w-16 h-16" />

        <button
          className="bg-transparent block md:hidden px-0 hover:bg-transparent text-white "
          onClick={() => setIsSidebarOpen((prev) => !prev)}
        >
          <IoMdClose size={24} />
        </button>
      </div>

      <div className="flex-grow px-4">
        <div className="py-2">
          <h2 className="text-xl font-semibold">ƏməkAI</h2>
          <span className="text-sm block">
            Əmək Aİ istifadəçilərin Azərbaycan Respublikası Əmək qanunvericiliyi
            istiqamətində suallarına Süni İntelekt əsalı sürətli və keyfiyyətli
            dəstək vermək məqsədi ilə yaradılmışdır.
          </span>
        </div>

        <div className="py-2">
          <h3 className="text-lg font-semibold">Əsas xüsusiyyətlər:</h3>
          <ol className="list-decimal list-inside text-left">
            <li>
              Əsasən İnsan Resursları peşəkarları və sahibkarlar üçün nəzərdə
              tutulub
            </li>
            <li>
              Sistem Əmək məcəlləsi və İnzibati xətalar məcəlləsini əhatə edir
            </li>
            <li>
              Sistemə əsasən gündəlik danışıq dili yox, dolğun və peşəkar dildə
              sorğu verilərsə daha doğru cavablar verir.
            </li>
            <li>
              Sistemin verdiyi cavablar hüquqi yardım məqsədi daşımır və ƏməkAI
              nəticələr ilə bağlı heç bir məsuliyyət götürmür. Hüquqi doğruluq
              üçün maddələrə və ya peşəkara müraciət etməyiniz daha məsləhətdir.
            </li>
            <li>
              ƏməkAİ çatbotu Mübariz Şahbazlı və AICLUB birgə təşəbbüsüdür.
            </li>
          </ol>
        </div>

        {/* Feedback Button */}
        <button
          className="mt-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleOpenModal}
        >
          Təkliflər və İradlar
        </button>

        {/* Modal for Feedback */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg text-black">
              <h2 className="text-lg font-semibold mb-2">Rəyiniz</h2>
              <textarea
                className="w-full h-32 p-2 border border-gray-300 rounded-lg"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)} // Handle change
              />

              <div className="text-right">
                <button
                  className="mt-2 bg-gray-200 hover:bg-gray-300 text-black font-bold py-1 px-4 rounded"
                  onClick={handleCloseModal}
                >
                  Ləğv et
                </button>
                <button
                  className="mt-2 bg-gray-200 hover:bg-gray-300 text-black font-bold py-1 px-4 rounded"
                  onClick={handleSubmitFeedback}
                >
                  Göndər
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import bgimage from "../../assets/img/chatbg.svg";
import logo from "../../assets/img/logo.webp";
import { useNavigate } from "react-router-dom";
import { HiOutlineMenuAlt1 } from "react-icons/hi";

function MessageList({ messages, isTyping, setIsSidebarOpen }) {
  const user = {
    username: "ƏməkAI",
    profilePhoto: logo,
    role: "AI Köməkçi",
  };

  const realUser = useSelector((state) => state.user);
  const navigate = useNavigate();

  const divStyle = {
    backgroundImage: `url(${bgimage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#eeeef1",
    position: "relative",
    zIndex: 1,
  };

  const userMessageStyle = {
    backgroundColor: "#3662ff",
    padding: "12px 16px",
    borderRadius: "16px 0 16px 16px",
    color: "#fff",
    alignSelf: "flex-end",
    maxWidth: "600px",
  };

  const nonUserMessageStyle = {
    position: "relative",
    backgroundColor: "#fff",
    padding: "12px 16px",
    borderRadius: "0 16px 16px 16px",
    color: "#000",
  };

  const headerStyle = {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    padding: "16px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const rightHeaderStyle = {
    display: "flex",
    alignItems: "center",
  };

  const typingIndicator = (
    <div className="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );

  const handleIconClick = () => {
    navigate("/plan");
  };

  return (
    <div style={divStyle} className="flex-1 flex flex-col">
      <div style={headerStyle}>
        <div className="flex items-center">
          <button className="bg-transparent block md:hidden hover:bg-transparent text-gray-700 px-0 mr-4" onClick={() => setIsSidebarOpen(prev => !prev)}>
            <HiOutlineMenuAlt1 size={28} />
          </button>
          <img
            src={user.profilePhoto}
            alt="Profile"
            className="h-10 w-10 rounded-full mr-3"
          />
          <div>
            <div className="font-semibold">{user.username}</div>
            <div className="text-sm">{user.role}</div>
            <div className="text-sm">
              Verilən sual sayı: {realUser.user.questions_asked}
            </div>
          </div>
        </div>
        <div style={rightHeaderStyle}>
          {realUser && (
            <>
              <div className="mr-4">
                <span className="font-semibold">
                  Hazırki planınız: {realUser.user.plan}
                </span>
              </div>
              <button onClick={handleIconClick}>
                <i className="fas fa-user-circle fa-1x"></i>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="flex-1 p-4 space-y-4 overflow-y-auto">
        {messages.map((message, index) => (
          <div
            key={index}
            style={
              message.role === "user" ? userMessageStyle : nonUserMessageStyle
            }
            className={`rounded px-4 py-2 max-w-xs lg:max-w-md ${
              message.role === "user" ? "ml-auto" : ""
            }`}
          >
            {message.text}
          </div>
        ))}
        {isTyping && typingIndicator}
      </div>
    </div>
  );
}

export default MessageList;

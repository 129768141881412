import React from "react";
import sendIcon from "../../assets/img/sendbtn.png";

function MessageInput({ userInput, setUserInput, sendMessage }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <form
      className="p-4 flex items-center"
      onSubmit={handleSubmit}
      style={{
        borderRadius: "9999px",
        position: "relative",
        zIndex: 10,
      }}
    >
      <input
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        placeholder="Sualınızı daxil edin…"
        className="input input-bordered flex-grow rounded-full bg-gray-200 text-gray-700 focus:ring-2 focus:ring-blue-400"
        style={{ backgroundColor: "#fff", border: "none", outline: "none" }}
      />
      <button
        type="submit"
        className="ml-2"
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <img src={sendIcon} alt="Send" width="24" height="24" />
      </button>
    </form>
  );
}

export default MessageInput;

import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/style";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState({});

  const validatePassword = (password) => {
    const errors = {};
    if (password.length < 6) {
      errors.length = "Şifrə uzunluğu ən az 6 simvol olmalıdır.";
    }
    if (!/[a-z]/.test(password)) {
      errors.lowercase = "Şifrə ən az 1 kiçik simvol təşkil etməlidir.";
    }
    if (!/[A-Z]/.test(password)) {
      errors.uppercase = "Şifrə ən az 1 böyük simvol təşkil etməlidir.";
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.specialChar = "Şifrə ən az 1 özəl simvol təşkil etməlidir.";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordErrors = validatePassword(password);
    if (Object.keys(passwordErrors).length > 0) {
      setErrors(passwordErrors);
      return;
    }

    if (password !== confirmPassword) {
      setErrors({ confirmPassword: "Şifrələr uyğun gəlmir." });
      return;
    }

    setErrors({});

    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const newForm = new FormData();
    newForm.append("email", email);
    newForm.append("password", password);

    try {
      const res = await axios.post(`${process.env.REACT_APP_AUTH_URL}/signup`, newForm, config);
      toast.success(res.data.message);
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      navigate("/login");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-semibold text-gray-900">
          Qeydiyyatdan keçin
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Hesabınız
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Şifrəniz
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={() => setVisible(!visible)}
                  className="absolute bg-transparent hover:bg-transparent inset-y-0 text-gray-500 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {visible ? (
                    <AiOutlineEyeInvisible size={25} />
                  ) : (
                    <AiOutlineEye size={25} />
                  )}
                </button>
              </div>
              {errors.length && <p className="text-red-500 text-xs mt-2">{errors.length}</p>}
              {errors.lowercase && <p className="text-red-500 text-xs mt-2">{errors.lowercase}</p>}
              {errors.uppercase && <p className="text-red-500 text-xs mt-2">{errors.uppercase}</p>}
              {errors.specialChar && <p className="text-red-500 text-xs mt-2">{errors.specialChar}</p>}
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Şifrənizi təsdiq edin
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="confirmPassword"
                  autoComplete="current-password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={() => setVisible(!visible)}
                  className="absolute bg-transparent hover:bg-transparent inset-y-0 text-gray-500 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {visible ? (
                    <AiOutlineEyeInvisible size={25} />
                  ) : (
                    <AiOutlineEye size={25} />
                  )}
                </button>
              </div>
              {errors.confirmPassword && <p className="text-red-500 text-xs mt-2">{errors.confirmPassword}</p>}
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Qeydiyyatdan keç
              </button>
            </div>
            <div
              className={`${styles.noramlFlex} w-full flex text-sm gap-x-1 justify-center`}
            >
              <h4>Hesabınız var?</h4>
              <Link to="/login" className="text-blue-600">
                Giriş edin
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/css/App.css";

function App() {
  const [loading, setLoading] = useState(true);
  const [threadId, setThreadId] = useState("");
  const [assistant_id, setAssistantId] = useState("");
  const [userInput, setUserInput] = useState("");
  const email = useSelector((state) => state.user?.user?.email || "");
  const [chatMessages, setChatMessages] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const initializeConversation = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/chat/start`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setThreadId(response.data.thread_id);
        setAssistantId(response.data.assistant_id);
        setLoading(false);
      } catch (error) {
        console.error("Error starting conversation:", error);
        setLoading(false);
      }
    };
    initializeConversation();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  const sendMessage = async () => {
    const trimmedInput = userInput.trim();
    if (!trimmedInput) return;

    setChatMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", text: trimmedInput },
    ]);

    setUserInput("");
    setIsTyping(true);

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/chat/chat`,
        {
          thread_id: threadId,
          assistant_id: assistant_id,
          message: trimmedInput,
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", text: response.data.response },
      ]);
      setIsTyping(false);
    } catch (error) {
      console.error("Error sending message:", error);
      setIsTyping(false);
      if (error.response && error.response.status === 500) {
        toast.error(
          "Planınız üçün sual limitinə çatdınız. Zəhmət olmasa planınızı yeniləyin.",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  };

  return (
    <>
      <div className="flex h-screen">
        <div
          className={`${isSidebarOpen ? "block" : "hidden"
            } md:flex md:w-64 bg-gray-700 text-white fixed inset-y-0 left-0 transform transition-transform duration-300 ease-in-out z-50`}
        >
          <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
        </div>
        <div className="flex-1 flex flex-col md:ml-64">
          <MessageList
            messages={chatMessages}
            isTyping={isTyping}
            setIsSidebarOpen={setIsSidebarOpen}
          />

          <MessageInput
            userInput={userInput}
            setUserInput={setUserInput}
            sendMessage={sendMessage}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;

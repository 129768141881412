import { useEffect } from "react";

export const useClickOutside = (
  ref,
  callback
) => {
  const handleClick = (e) => {
    if (ref.current) {
      if (!e.composedPath().includes(ref?.current)) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
};
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { token } = useParams();

  const validatePassword = (password) => {
    const errors = {};
    if (password.length < 6) {
      errors.length = "Şifrə uzunluğu ən az 6 simvol olmalıdır.";
    }
    if (!/[a-z]/.test(password)) {
      errors.lowercase = "Şifrə ən az 1 kiçik simvol təşkil etməlidir.";
    }
    if (!/[A-Z]/.test(password)) {
      errors.uppercase = "Şifrə ən az 1 böyük simvol təşkil etməlidir.";
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.specialChar = "Şifrə ən az 1 özəl simvol təşkil etməlidir.";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const passwordErrors = validatePassword(password);
    if (Object.keys(passwordErrors).length > 0) {
      setErrors(passwordErrors);
      return;
    }

    if (password !== confirmPassword) {
      setErrors({ confirmPassword: "Şifrələr uyğun gəlmir." });
      return;
    }

    setErrors({});

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/reset_password/${token}`,
        { password: password }
      );
      alert("Şifrə uğurla yeniləndi");
    } catch (error) {
      alert("Şifrə yenilənərkən problem çıxdı.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="p-8 bg-white shadow-md rounded-lg w-full max-w-sm">
        <label className="block mb-5">
          Yeni şifrə:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="mt-1 p-2 w-full border rounded-md"
          />
          {errors.length && <p className="text-red-500 text-xs mt-2">{errors.length}</p>}
          {errors.lowercase && <p className="text-red-500 text-xs mt-2">{errors.lowercase}</p>}
          {errors.uppercase && <p className="text-red-500 text-xs mt-2">{errors.uppercase}</p>}
          {errors.specialChar && <p className="text-red-500 text-xs mt-2">{errors.specialChar}</p>}
        </label>
        <label className="block mb-5">
          Yeni şifrəni təkrarla:
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="mt-1 p-2 w-full border rounded-md"
          />
          {errors.confirmPassword && <p className="text-red-500 text-xs mt-2">{errors.confirmPassword}</p>}
        </label>
        <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Şifrəni sıfırla
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;

import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/style";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/login`,
        { email, password },
        { withCredentials: true }
      );

      localStorage.setItem("access_token", data.token);
      toast.success("Uğurlu giriş!");
      navigate("/");
      window.location.reload(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_AUTH_URL}/forgot_password`, {
        email: resetEmail,
      });
      toast.success("Sorğunuz emailinizə göndərildi.");
      setResetModalOpen(false);
    } catch (err) {
      toast.error(err.response?.data?.message || "Xəta baş verdi.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-semibold text-gray-900">
          Hesabınıza giriş edin
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* Email input */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Hesabınız
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            {/* Password input */}
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Şifrəniz
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={() => setVisible(!visible)}
                  className="absolute bg-transparent hover:bg-transparent inset-y-0 text-gray-500 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {visible ? (
                    <AiOutlineEyeInvisible size={25} />
                  ) : (
                    <AiOutlineEye size={25} />
                  )}
                </button>
              </div>
            </div>
            {/* Remember me and Forget password link */}
            <div className={`${styles.normalFlex} justify-between `}>
              <div className="text-sm -mt-4">
                <button
                  type="button"
                  className="font-medium text-blue-600 text-sm hover:text-blue-500 bg-transparent hover:bg-transparent px-0"
                  onClick={() => setResetModalOpen(true)}
                >
                  Şifrənizi unutmusunuz?
                </button>
              </div>
            </div>
            {/* Submit button */}
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Daxil ol
              </button>
            </div>
            {/* Sign up link */}
            <div
              className={`${styles.normalFlex} w-full flex text-sm gap-x-1 justify-center`}
            >
              <h4>Hesabınız yoxdur?</h4>
              <Link to="/sign-up" className="text-blue-600">
                Qeydiyyatdan keçin
              </Link>
            </div>
          </form>
        </div>
      </div>
      {/* Reset Password Modal */}
      {isResetModalOpen && (
        <div className="fixed p-4 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white w-[492px] space-y-6 p-4 rounded-lg text-black">
            <h2 className="text-lg font-semibold">
              Şifrəni sıfırla
            </h2>
            <form onSubmit={handleResetPassword}>
              <input
                type="email"
                placeholder="Email adressinizi daxil edin"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                className="w-full h-10 p-2 border border-gray-300 rounded-lg"
                required
              />
              <div className="text-right space-x-2 mt-6">
                <button
                  type="button"
                  className="!border !border-gray-300 bg-transparent hover:bg-transparent p-2 px-4 rounded-lg text-black"
                  onClick={() => setResetModalOpen(false)}
                >
                  Ləğv et
                </button>
                <button
                  type="submit"
                  className="border border-transparent rounded-lg p-2 px-4 text-white bg-blue-600 hover:bg-blue-700"
                >
                  Təsdiq et
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;

import React from "react";
import Plan from "../components/Plan/Plan";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PlanPage = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex min-h-screen bg-gray-100 items-center justify-center p-4">
      <Plan />
    </div>
  );
};

export default PlanPage;
